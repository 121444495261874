<template>
  <div>

    <h3 class="gavc-h3">Présentez-vous votre projet</h3>

    <p class="gavc-label mt-3">
      Vous reprenez une entreprise parce que ?
      <span class="gavc-required"> * </span>
    </p>

    <div
      class="gavc-field">
      <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
        <div class="gavc-radio-icon" v-for="option in repreneurPersonalProjectOptions" :key="option.value">
          <input v-model="repreneurPersonalProject"  type="radio" :id="`registration-step-1-${option.value}`" :name="`registration-step-1-${option.value}`" :value="option.value">
          <label :for="`registration-step-1-${option.value}`">
              <i :class="`gavc-icon gavc-icon-${option.icon}`"></i>
              {{option.text}}
          </label>
        </div>
      </div>
      <p v-if="$v.repreneurPersonalProject.$dirty ? $v.repreneurPersonalProject.$error : null " class="gavc-p gavc-text-rose">
        <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
      </p>
    </div>

    <div v-if="repreneurPersonalProject === 'AUTRE'"
         class="gavc-field"
         v-bind:class="{ 'is-error': $v.repreneurPersonalProjectPrecision.$dirty ? $v.repreneurPersonalProjectPrecision.$error : null  }">
      <label class="gavc-label" for="registration-step-1-repreneurPersonalProjectPrecision-group">
        Veuillez préciser votre projet de reprise :
        <span class="gavc-required"> * </span>
      </label>
      <textarea
        class="gavc-textarea"
        id="registration-step-1-repreneurPersonalProjectPrecision-group"
        maxlength="1000"
        placeholder="Décrivez votre projet de reprise"
        @input="$v.repreneurPersonalProjectPrecision.$touch()"
        type="text"
        v-model="repreneurPersonalProjectPrecision">
      </textarea>
      <p v-if="$v.repreneurPersonalProjectPrecision.$dirty ? $v.repreneurPersonalProjectPrecision.$error : null " class="gavc-p gavc-text-rose">
        <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information (1000 caractères maximum)
      </p>
    </div>

    <hr>

    <h3 class="gavc-h3">Dites-nous en davantage sur vous</h3>

    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.repreneurUserFirstName.$dirty ? $v.repreneurUserFirstName.$error : null  }">
          <label class="gavc-label" for="registration-step-1-repreneurUserFirstName-group">
            Prénom
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-repreneurUserFirstName-group"
            maxlength="128"
            @input="$v.repreneurUserFirstName.$touch()"
            type="text"
            v-model="repreneurUserFirstName"
          >
          <p v-if="$v.repreneurUserFirstName.$dirty ? $v.repreneurUserFirstName.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Votre prénom doit comporter entre 2 et 128 caractères
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.repreneurUserLastName.$dirty ? $v.repreneurUserLastName.$error : null  }">
          <label class="gavc-label" for="registration-step-1-repreneurUserLastName-group">
            Nom
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-repreneurUserLastName-group"
            maxlength="128"
            @input="$v.repreneurUserLastName.$touch()"
            type="text"
            v-model="repreneurUserLastName"
          >
          <p v-if="$v.repreneurUserLastName.$dirty ? $v.repreneurUserLastName.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Votre nom doit comporter entre 2 et 128 caractères
          </p>
        </div>
      </div>
    </div>

    <div
      class="gavc-field mt-2"
      v-bind:class="{ 'is-error': $v.repreneurUserPhoneNumber.$error && $v.repreneurUserPhoneNumber.$dirty }"
    >
      <label class="gavc-label" for="registration-step-1-repreneurUserPhoneNumber-group">
        Téléphone mobile
      </label>
      <input
        class="gavc-input"
        id="registration-step-1-repreneurUserPhoneNumber-group"
        maxlength="14"
        @input="$v.repreneurUserPhoneNumber.$touch()"
        type="tel"
        placeholder="06 __ __ __ __"
        v-mask="'XX XX XX XX XX'"
        v-model="maskedPhoneNumber"
      >
      <inputErrorValidationMessage
        v-if="$v.repreneurUserPhoneNumber.$invalid"
        message="Votre numéro de téléphone doit être composé de 10 chiffres"
      />
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <label class="gavc-label" for="registration-step-1-repreneurIsPersonnePhysique">
            Vous reprenez une entreprise directement ou par le biais d’une société ?
            <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-radio">
          <input :value="true" type="radio" id="repreneurIsPersonnePhysique-input-1"
                 name="registration-step-1-repreneurIsPersonnePhysique" v-model="repreneurIsPersonnePhysique">
          <label class="gavc-label" for="repreneurIsPersonnePhysique-input-1">
            Directement (personne physique ou société en cours de constitution)
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
          <br>
          <input :value="false" type="radio" id="repreneurIsPersonnePhysique-input-2"
                 name="repreneurIsPersonnePhysique-input-2" v-model="repreneurIsPersonnePhysique">
          <label class="gavc-label" for="repreneurIsPersonnePhysique-input-2">
            Par le biais d'une société constituée
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
        </div>
        <p v-if="$v.repreneurIsPersonnePhysique.$dirty ? $v.repreneurIsPersonnePhysique.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <span @mouseenter="$v.$touch()">
          <button
            class="mt-2 float-right gavc-btn"
            @click="nextStep"
            :disabled="isInvalidInformation">
              Suivant
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'
import { createHelpers } from 'vuex-map-fields';

import { repreneurPersonalProjectList } from '@/variables/repreneurPersonalProjectList'


const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'repreneurRegistrationProject',
  mixins: [
    validationMixin
  ],

  data: function () {
    return {
      repreneurPersonalProjectOptions: repreneurPersonalProjectList,

      maskedPhoneNumber: ''
    }
  },

  computed: {
    ...mapFields([
      'repreneur.repreneurPersonalProject', 'repreneur.repreneurPersonalProjectPrecision',
      'repreneur.repreneurIsPersonnePhysique',

      'repreneur.user.repreneurUserPhoneNumber', 'repreneur.user.repreneurUserFirstName', 'repreneur.user.repreneurUserLastName',
    ]),
    isInvalidInformation () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.maskedPhoneNumber = this.repreneurUserPhoneNumber
  },
  watch: {
    maskedPhoneNumber (value) {
      this.repreneurUserPhoneNumber = value.replace(/ /g,'')
    }
  },
  validations: {
    repreneurIsPersonnePhysique: {
      required,
    },
    repreneurUserPhoneNumber: {
      minLength: minLength(10),
      maxLength: maxLength(10)
    },

    repreneurUserFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(128)
    },
    repreneurUserLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    repreneurPersonalProject: {
      required,
    },
    repreneurPersonalProjectPrecision: {
      required: requiredIf(function() {
        return this.repreneurPersonalProject === 'AUTRE'
      }),
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },
  },
  methods: {
    nextStep () {
      this.$emit('nextStep')
    }
  }
}
</script>
